


















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CollapsiblePanel extends Vue {
  @Prop(String)
  readonly title!: string;

  @Prop(String)
  readonly body!: string;

  @Prop(Boolean)
  readonly open!: boolean;
}
