




















import { Component, Vue } from "vue-property-decorator";
import CollapsiblePanel from "../base/CollapsiblePanel.vue";

@Component({
  components: {
    CollapsiblePanel,
  },
})
export default class ForgotPasswordFaq extends Vue {
  get FaqTexts(): unknown {
    type itemsType =
      | "forgot-password-email"
      | "forgot-password-sms"
      | "forgot-password-password";

    const current = this.$route.name as itemsType;

    const items = {
      "forgot-password-email": [
        {
          title: this.$t("ChangePassword_InputAccount_HelpSection1_Title"),
          answer: (
            this.$t(
              "ChangePassword_InputAccount_HelpSection1_Content"
            ) as string
          ).replace(/\\n/g, "<br>"),
        },
        {
          title: this.$t("ChangePassword_InputAccount_HelpSection2_Title"),
          answer: this.$t("ChangePassword_InputAccount_HelpSection2_Content"),
        },
        {
          title: this.$t("SignUp_Phone_FAQ_Question3"),
          answer: this.$t("SignUp_Phone_FAQ_Answer3"),
        },
      ],
      "forgot-password-sms": [
        {
          title: this.$t("ChangePassword_InputCode_HelpSection1_Title"),
          answer: this.$t("ChangePassword_InputCode_HelpSection1_Content"),
        },
        {
          title: this.$t("ChangePassword_InputAccount_HelpSection2_Title"),
          answer: this.$t("ChangePassword_InputAccount_HelpSection2_Content"),
        },
        {
          title: this.$t("SignUp_Phone_FAQ_Question3"),
          answer: this.$t("SignUp_Phone_FAQ_Answer3"),
        },
      ],
      "forgot-password-password": [
        {
          title: this.$t("ChangePassword_InputPassword_HelpSection1_Title"),
          answer: this.$t("ChangePassword_InputPassword_HelpSection1_Content"),
        },
        {
          title: this.$t("ChangePassword_InputAccount_HelpSection2_Title"),
          answer: this.$t("ChangePassword_InputAccount_HelpSection2_Content"),
        },
        {
          title: this.$t("SignUp_Phone_FAQ_Question3"),
          answer: this.$t("SignUp_Phone_FAQ_Answer3"),
        },
      ],
    };

    return items[current];
  }
}
